<template>
  <div>
    <div class="relative" :class="{ inverse: inverse, agile__home: home }">
      <div v-if="amountOfSlides == 1">
        <slot :clickImageAt="clickImageAt" class="border"></slot>
      </div>
      <agile
        v-else-if="updateFlag"
        ref="carousel"
        :options="options"
        :autoplay="autoplay"
        :speed="speed"
        :autoplay-speed="autoplaySpeed"
        v-bind="$props"
        @afterChange="onAfterChange"
        @beforeChange="onBeforeChange"
      >
        <slot :clickImageAt="clickImageAt"></slot>
        <template slot="prevButton">
          <div class="px-4">
            <arrow prev :inverse="inverse" class="hover:opacity-50"></arrow>
          </div>
        </template>
        <template slot="nextButton">
          <div class="px-4">
            <arrow :inverse="inverse" class="hover:opacity-50"></arrow>
          </div>
        </template>
      </agile>
      <div
        v-if="showCounter"
        :class="counterClass"
        class="absolute bottom-0 inset-x-0  mb-1 text-center  pointer-events-none"
      >
        <p>{{ currentSlide + 1 }} / {{ amountOfSlides }}</p>
      </div>
      <div
        v-if="hasAnnotations"
        class="absolute inset-0 bottom-0 m-6 sm:m-16 z-10 text-white pointer-events-none"
      >
        <div class="h-full flex flex-col justify-between">
          <h2
            class="sm:pt-8 transition-duration text-shadow sm:text-shadow-large text-3xl  lg:text-5xl hidden sm:block  transition-duration text-2xl leading-none w-2/3"
          >
            {{ annotations.title }}
          </h2>
          <div class="ml-0 sm:ml-16">
            <h1
              :class="{ 'opacity-0': currentSlide != 0 }"
              class="mt-16 sm:mt-0 text-3xl  lg:text-5xl leading-none  mr-32 text-shadow sm:text-shadow-large"
            >
              {{ annotations.quote }}
            </h1>
            <h3 class="mb-4 hidden sm:block text-xl text-shadow">
              {{ annotations.description }}
            </h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import inViewport from 'vue-in-viewport-mixin'
import isEmpty from 'lodash/isEmpty'
import objectFitPolyfill from './../modules/object-fit-polyfill'
export default {
  name: 'AgileGallery',
  mixins: [inViewport],
  props: {
    annotations: {
      type: [Object, Boolean],
      required: false,
      default: false
    },
    slidesToShow: {
      type: Number,
      required: false,
      default: 1.2
    },
    initialSlide: {
      type: Number,
      required: false,
      default: 0
    },
    amountOfSlides: {
      type: undefined,
      required: false,
      default: null
    },
    inverse: {
      type: Boolean,
      required: false,
      default: false
    },
    wideResponsive: {
      type: Boolean,
      required: false,
      default: false
    },
    fade: {
      type: Boolean,
      required: false,
      default: false
    },
    initialAutoplay: {
      type: Boolean,
      required: false,
      default: false
    },
    home: {
      type: Boolean,
      required: false,
      default: false
    },
    navButtons: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data() {
    return {
      emittedScroll: false,
      loop: this.initialAutoplay,
      changing: false,
      visible: false,
      updateFlag: true,
      currentSlide: this.initialSlide || 0,
      autoplaySpeed: 6000
    }
  },
  computed: {
    autoplay() {
      return this.loop
    },
    speed() {
      return this.fade ? 1000 : 500
    },
    showCounter() {
      return this.amountOfSlides > 5
    },
    hasAnnotations() {
      if (isEmpty(this.annotations)) return false
      return true
    },
    carousel() {
      return this.$refs.carousel
    },
    options() {
      return {
        initialSlide: this.currentSlide,
        dots: !this.showCounter,
        navButtons: this.navButtons,
        pauseOnHover: false,
        pauseOnDotsHover: true,
        timing: 'ease-in-out',
        slidesToShow: this.responsiveSlidesToShowSmall,
        responsive: [
          {
            breakpoint: 640,
            settings: {
              slidesToShow: this.responsiveSlidesToShow
            }
          }
        ]
      }
    },
    responsiveSlidesToShowSmall() {
      return 1
    },
    responsiveSlidesToShow() {
      if (!this.wideResponsive) return this.slidesToShow
      return this.window === 'wide' ? 1.6 : 1.2
    },
    window(){
      return this.$store.state.window
    },
    counterClass() {
      const names = []
      names.push(this.inverse ? 'text-white inverse' : 'text-black')
      return names
    }
  },
  watch: {
    'inViewport.now': function(visible) {
      this.visible = visible
    },
    window(newValue, oldValue) {
      if (!this.wideResponsive) return
      if (newValue != oldValue) {
        this.reload()
      }
    }
  },
  mounted() {
   
  },
  created() {
    window.addEventListener('scroll', this.scrollHandler)
    window.addEventListener('keyup', this.keyHandler)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.scrollHandler)
    window.removeEventListener('keyup', this.keyHandler)
  },
  methods: {
    reload() {
      this.updateFlag = false
      this.$nextTick(() => {
        this.updateFlag = true
      })
    },
    clickImageAt(index) {
      if (this.changing) return
      if (index - 1 != this.currentSlide) {
        this.carousel.goToNext()
      }
    },
    scrollHandler() {
      this.emittedScroll = true
      window.removeEventListener('scroll', this.scrollHandler)
    },
    keyHandler(e) {
      if (this.fade) return
      if (!this.visible) return
      if (!this.carousel) return

      switch (e.keyCode) {
        case 37: // left
          this.carousel.goToPrev()
          break
        case 39: //  right
          this.carousel.goToNext()
          break
      }
    },

    onBeforeChange(e) {
      this.$root.clickOutsideCard()
      if (
        this.home &&
        e.currentSlide == this.amountOfSlides - 1 &&
        !this.emittedScroll
      ) {
        this.emittedScroll = true

        this.$scrollTo('body', 1000, { offset: 600 })
      }
      this.changing = true
      setTimeout(() => {
        this.changing = false
      }, this.speed)
    },
    onAfterChange(e) {
      objectFitPolyfill()
      this.currentSlide = e.currentSlide
    }
  }
}
</script>
