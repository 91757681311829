import Vuex from 'vuex'
import Vue from 'vue'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    width: window.innerWidth,
    window: 'wide',
    menuLight: false
  },
  mutations: {
    SET_WIDTH(state, width) {
      state.width = width
    },
    SET_WINDOW(state, window) {
      state.window = window
    },
    SET_MENU_LIGHT(state, flag) {
      state.menuLight = flag
    }
  }
})

export default store
