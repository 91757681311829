<template>
  <div ref="block" :class="blockClass">
    <span :class="arrowClass">
      <svg
        :style="svgStyle"
        class="inline"
        width="23px"
        height="20px"
        viewBox="0 0 23 20"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
      >
        <g id="Page-1" stroke="none" stroke-width="1">
          <g
            id="13s_arrow_right"
            :transform="transform"
            :fill="computedArrowColor"
          >
            <polygon
              points="10.1 0 11.8 0 2.7 9.1 22.4 9.1 22.4 10.3 2.7 10.3 11.8 19.4 10.1 19.4 0.4 9.7"
            ></polygon>
          </g>
        </g>
      </svg>
    </span>
  </div>
</template>

<script>
// import left from './../assets/13s_arrow_left.svg'
// import right from './../assets/13s_arrow_right.svg'

export default {
  name: 'Arrow',
  props: {
    block: {
      type: Boolean,
      default: false,
      required: false
    },
    small: {
      type: Boolean,
      default: false,
      required: false
    },
    prev: {
      type: Boolean,
      default: false,
      required: false
    },
    center: {
      type: Boolean,
      default: false,
      required: false
    },
    inverse: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data() {
    return {
      arrowColor: 'transparent'
    }
  },
  computed: {
    computedArrowColor() {
      if (this.block) return 'black'
      if (this.inverse) return 'white'
      return this.arrowColor
    },
    transform() {
      return this.prev
        ? ''
        : 'translate(11.500000, 10.000000) rotate(-180.000000) translate(-11.500000, -10.000000)'
    },
    blockClass() {
      if (!this.block) return ['inline']

      const names = [
        'absolute',
        'pointer-events-none',
        'inset-y-0',
        'flex',
        'flex-col'
      ]
      names.push(this.center ? 'justify-center' : 'justify-end')
      names.push(this.prev ? 'left-0' : 'right-0')

      return names
    },
    arrowClass() {
      if (!this.block) {
        if (this.small) return ['inline']
        if(this.prev) return ['inline', 'pl-2']
        return ['inline', 'pr-2']
      }

      const names = ['p-4', 'pointer-events-auto']
      names.push(this.inverse ? 'bg-black' : 'bg-white ')
      return names
    },
    svgStyle() {
      if (!this.block) {
        if (this.small)
          return {
            transform: 'scale(1)',
            marginTop: '-4px',
            width: '10px'
          }
        return {
          marginTop: '-4px'
        }
      }
      return {}
    }
  },
  mounted() {
    const block = this.$refs.block.parentElement
    const styles = window.getComputedStyle(block)
    this.arrowColor = styles.getPropertyValue('color')
  }
}
</script>
