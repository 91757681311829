<template>
  <transition appear name="fade">
    <div
      v-show="src"
      class="fixed inset-0 h-screen inset-0 z-10 bg-13s-dark-translucent p-2 pb-10 z-50"
    >
      <div class="relative h-full w-full pt-10 overflow-hidden">
        <div class="absolute inset-0 mt-10 bg-white cont-2 overflow-hidden">
          <iframe ref="frame" :src="src"></iframe>
        </div>
      </div>
      <div class="text-white absolute inset-x-0 cont top-0 flex justify-end">
        <span class="text-right text-shadow leading-none text-5xl px-4">
          <span
            class="pointer-events-auto cursor-pointer text-shadow"
            @click="close"
            >&times;</span
          >
        </span>
      </div>
    </div>
  </transition>
</template>

<script>
import {
  disableBodyScroll,
  // enableBodyScroll,
  clearAllBodyScrollLocks
} from 'body-scroll-lock'
export default {
  name: 'Framer',
  props: {
    // src: {
    //   type: String,
    //   required: false,
    //   default:
    //     'https://us18.campaign-archive.com/?u=66640631b3b7b41c694c40b0e&id=84200acde9'
    // }
  },
  data() {
    return {
      src: '',
      arrowColor: 'transparent'
    }
  },
  created() {
    window.addEventListener('keyup', this.keyHandler)
  },
  beforeDestroy() {
    window.removeEventListener('keyup', this.keyHandler)
  },
  mounted() {
    const bodyParent = document.getElementById('update_links')

    for (const el of bodyParent.getElementsByTagName('a')) {
      // console.log(el.host)
      const includes = ['us18.campaign-archive.com', 'mailchi.mp'].includes(
        el.host
      )
      if (includes) {
        el.addEventListener('click', event => {
          this.open(el.href)
          event.preventDefault()
          //  })
          // alert('Element clicked through function!');
        })
      }
      // el.addEventListener('mousemouse', event => {
    }

    // enableBodyScroll(this.$refs.project)
    console.log('framer mounted')
  },

  methods: {
    keyHandler(e) {
      switch (e.keyCode) {
        case 27: // esc
          this.close()
          break
      }
    },
    close() {
      this.src = ''
      clearAllBodyScrollLocks()
    },
    open(src) {
      this.src = src
      disableBodyScroll(this.$refs.frame, { allowTouchMove: false })
    }
  }
}
</script>

<style scoped>
.cont-2 {
  max-width: 640px;
  margin: 0 auto;
  top: 50px;
}
.cont {
  max-width: 600px;
  margin: 0 auto;
}

iframe {
  display: block; /* iframes are inline by default */
  background: #000;
  position: absolute;
  top: -115px;
  bottom: 10px;
  right: 0px;
  left: 0px;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  overflow: hidden;
  height: calc(100% + 115px);
}
</style>
