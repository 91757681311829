<template>
  <div class="absolute" style="transition:opacity 1s" :style="styleObject">
    <div
      ref="tag"
      class="tag cursor-pointer text-sm text-shadow absolute border text-white uppercase text-center leading-none w-20 h-20 rounded-full -translate-50% flex justify-center items-center"
      @click="clickTag"
    >
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Tag',
  props: {
    url: { type: String, required: false, default: '' },
    description: { type: String, required: false, default: '' },
    pos: { type: Object, required: false, default: () => {} },
    type: { type: String, required: false, default: '' },
    offsets: {
      type: Array,
      required: true,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      picture: null
    }
  },
  computed: {
    styleObject() {
      if (!this.picture) return { opacity: '0' }

      const selectedOffset =
        this.$store.state.width > 640
          ? this.offsets[0]
          : this.offsets[this.offsets.length - 1]

      const tagTop =
        this.pos.y * selectedOffset.y.adjust - selectedOffset.y.offset
      const topRatio =
        this.picture.offsetHeight / this.$el.parentNode.offsetHeight
      const tagLeft =
        this.pos.x * selectedOffset.x.adjust - selectedOffset.x.offset
      const leftRatio =
        this.picture.offsetWidth / this.$el.parentNode.offsetWidth

      const calcTop = `calc(${tagTop * topRatio}% + ${
        this.picture.offsetTop
      }px)`
      const calcLeft = `calc(${tagLeft * leftRatio}% + ${
        this.picture.offsetLeft
      }px)`

      return {
        top: calcTop,
        left: calcLeft
      }
    }
  },
  mounted() {
    setTimeout(() => {
      this.$nextTick(() => {
        this.$el.parentNode.style.overflow = 'hidden'
        this.picture = this.$el.parentNode
          .getElementsByTagName('picture')[0]
          .getElementsByTagName('img')[0]
      })
    }, 1000)
  },
  methods: {
    clickTag() {
      this.$emit('tag-click', {
        description: this.description,
        type: this.type,
        url: this.url,
        offset: this.offset()
      })
    },
    offset() {
      var rect = this.$refs.tag.getBoundingClientRect(),
        scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
        scrollTop = window.pageYOffset || document.documentElement.scrollTop

      let ding = rect.width / 2
      return {
        opacity: 1,
        top: rect.bottom + scrollTop + 20,
        left: Math.min(
          Math.max(rect.left + scrollLeft - ding, 8),
          window.innerWidth - 200 - 8
        )
      }
    }
  }
}
</script>
