<template>
  <div>
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: 'Mainmenu',
  props: {},
  mounted() {},
  methods: {}
}
</script>
