<template>
  <transition appear name="fade">
    <div>
      <transition appear name="fade">
        <div
          v-show="project"
          class="fixed inset-0 bg-13s-dark-translucent pointer-events-auto z-50"
        >
          <div
            class="relative text-xl z-20 py-2 px-2 sm:px-10 text-white  flex flex-col justify-end"
          >
            <span class="text-right text-shadow leading-none text-5xl -mt-3">
              <span class=" cursor-pointer text-shadow" @click="close">
                &times;</span
              >
            </span>
          </div>
          <div
            ref="project"
            class="absolute inset-0 p-2 sm:p-8 pt-20 overflow-auto"
          >
            <div v-if="project">
              <div class="container">
                <div class="row">
                  <div class="col">
                    <h2 class="text-center mb-4 text-3xl text-white">
                      {{ project.title }}
                    </h2>
                  </div>
                </div>
              </div>

              <div class="relative">
                <agile-gallery
                  ref="carousel"
                  class="mb-6 "
                  :initial-slide="currentSlide"
                  :slides-to-show="1"
                  :inverse="true"
                  :amount-of-slides="gallery.length"
                >
                  <div
                    v-for="slide of gallery"
                    :key="slide.url"
                    class="slide relative"
                  >
                    <div class=" h-screen-3/4 w-full">
                      <img
                        :src="slide.url"
                        class="w-full h-full"
                        data-object-fit="cover"
                        @load="loaded"
                      />
                    </div>
                    <div
                      v-if="slide.project"
                      class="text-white absolute m-4 left-0 bottom-0 text-left"
                    >
                      <h3>{{ slide.project.title }}</h3>
                      <a
                        class=" text-shadow-small font-bold uppercase text-xs hover:text-13s-blue "
                        :href="slide.project.url"
                      >
                        <arrow></arrow> bekijk hele project
                      </a>
                    </div>
                  </div>
                </agile-gallery>
                <div
                  v-if="relatedProject"
                  class="text-white absolute m-4  mb-16 left-0 bottom-0 text-left"
                >
                  <h3>{{ relatedProject.title }}</h3>
                  <a
                    class=" text-shadow-small font-bold uppercase text-xs hover:text-13s-blue "
                    @click="gotoRelatedProject(relatedProject)"
                  >
                    <arrow></arrow> bekijk hele project
                  </a>
                </div>
              </div>
              <div class="container">
                <div class="row text-white">
                  <div class="sm:offset-3 sm:col-10">
                    <span v-dompurify-html="project.body"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </transition>
</template>
<script>
import axios from 'axios'
import objectFitPolyfill from './../modules/object-fit-polyfill'
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks
} from 'body-scroll-lock'

const map = {
  keukens: 'kitchens',
  badkamers: 'bathrooms',
  'totaal-projecten': 'interiorProjects',
  meubels: 'furniture',
  'kantoren-winkels': 'offices'
}

export const request = axios.create({
  baseURL: '/index.php?p=admin/actions/'
})

export default {
  name: 'Slider',
  data() {
    return {
      slug: '',
      topPath: '',
      project: null,
      // gallery: [],
      mounted: false,
      currentSlide: 0
    }
  },
  computed: {
    gallery() {
      // return []
      return this.project ? this.project.gallery : []
    },
    relatedProject() {
      return this.hasRelatedProject(this.project)
    }
  },
  watch: {
    $route(to) {
      this.checkRoute(to)
    }
  },
  created() {
    window.addEventListener('keyup', this.keyHandler)
  },
  beforeDestroy() {
    window.removeEventListener('keyup', this.keyHandler)
  },
  mounted() {
    setTimeout(() => {
      this.checkRoute(this.$route)
    }, 1000)
  },
  methods: {
    keyHandler(e) {
      if (!this.project) return

      switch (e.keyCode) {
        case 27: // esc
          this.close()
          break
      }
    },
    hasRelatedProject(project) {
      return project &&
        project.relatedProject &&
        project.relatedProject.length > 0
        ? project.relatedProject[0]
        : null
    },
    close() {
      this.currentSlide = 0
      this.$router.push({ path: `/interieur/${this.topPath}` })
      clearAllBodyScrollLocks()
    },
    gotoRelatedProject() {
      const url = `${window.location.origin}/${this.relatedProject.uri}`
      window.location.assign(url)
    },
    loaded(e) {
      objectFitPolyfill(e.target)
    },
    async setProject(project) {
      if (project) {
        if (!this.hasRelatedProject(project)) {
          const gallery = []
          for (const image of project.gallery) {
            try {
              const response = await axios.get('/related', {
                responseType: 'text',
                params: {
                  assetId: image.id
                }
              })

              if (response.data) {
                const project = {
                  title: response.data.title,
                  url: `${window.location.origin}/${response.data.uri}`
                }
                const newImage = { ...image, project: project }

                gallery.push(newImage)
              } else {
                gallery.push(image)
              }
            } catch (error) {
              console.log(error)
            }
          }
          project.gallery = gallery
        }
        disableBodyScroll(this.$refs.project, { allowTouchMove: false })
      } else {
        enableBodyScroll(this.$refs.project)
      }
      this.project = project
      this.$store.commit('SET_MENU_LIGHT', project ? true : false)
    },
    async checkRoute(route) {
      const path = route.path.split('/')
      this.slug = path[path.length - 1]
      this.topPath = path[2]
      const section = map[this.topPath]

      if (section && this.slug) {
        try {
          const response = await axios({
            url: '/api',
            method: 'post',
            data: {
              query: `{
              entries(section:"${section}", slug:"${this.slug}"  ){
                title
                sectionHandle
                slug
                ... on ${section}_${section}_Entry{
                  body
                   relatedProject {
                    title
                    uri
                  }
                  gallery{
                    id
                    url @transform(handle:"wide")
                  }
                }
              }
            }`
            }
          })
          this.setProject(response.data.data.entries[0])
        } catch (e) {
          this.setProject(null)
        }
      } else {
        this.setProject(null)
      }
    }
  }
}
</script>
