<template>
  <div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: ['links'],
  data() {
    return {
      slug: null
    }
  },
  watch: {
    $route(to) {
      this.checkRoute(to)
    }
  },
  mounted() {
    setTimeout(() => {
      this.checkRoute(this.$route)
    }, 1000)
  },
  methods: {
    async checkRoute(route) {
      const path = route.path.split('/')
      if (path.length === 4) {
        this.slug = path[path.length - 1]
        const index = this.links.findIndex(link => link.slug == this.slug)
        this.$root.$scrollTo(index)
      }
    }
  }
}
</script>
