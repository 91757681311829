<template>
  <div>
    <slot> </slot>
  </div>
</template>

<script>
export default {
  name: 'Website',
  props: {
    info: {
      type: Object,
      required: false,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return () => {
      'Hello World'
    }
  }
}
</script>
