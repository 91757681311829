import '@babel/polyfill'
import Vue from 'vue'
import inViewportDirective from 'vue-in-viewport-directive'
import store from './store'
import './modules/object-fit-polyfill'

//click outside
import vClickOutside from 'v-click-outside'

Vue.use(vClickOutside)

//carousel
import VueAgile from 'vue-agile'
Vue.use(VueAgile)

// router
import VueRouter from 'vue-router'
Vue.use(VueRouter)

// scrollspy

import Scrollspy, { Easing } from 'vue2-scrollspy'
Vue.use(Scrollspy, {
  easing: Easing.Cubic.InOut
})

// purify DOM
import VueDOMPurifyHTML from 'vue-dompurify-html'
Vue.use(VueDOMPurifyHTML)

// import Slider from './components/Slider'
const router = new VueRouter({
  mode: 'history'
  // routes: [{ path: '*', component: Slider }]
})

import './components'
import './app.scss'

//spy stuff
import throttle from 'lodash/throttle'
import debounce from 'lodash/debounce'

Vue.directive('in-viewport', inViewportDirective)
Vue.config.productionTip = false

const scrollClass = 'scrolled'
const events = ['touchmove', 'touchend', 'scroll']
const pageOffset = 50

new Vue({
  el: '#v-app',
  store,
  delimiters: ['[[', ']]'],
  router,

  data() {
    return {
      section: 0,
      // slidesToShow: 1.2,
      mounted: false,

      // menu
      open: false,
      state: '',

      //spy
      active: false,
      sections: [],

      // things for the cards
      x: 10,
      y: 10,
      showCard: false,
      description: '',
      type: '',
      url: '',
      linkVisible: true,
      height: '344px'
    }
  },

  computed: {
    menuClass() {
      let names = this.$store.state.menuLight
        ? ['text-white', 'border-white']
        : ['text-black', 'border-black ']

      if (this.open ) names.push('opacity-0 pointer-events-none')
      return names
    }
  },
  created() {
    this.checkMethod()
    for (let event of events) {
      document.addEventListener(event, throttle(() => this.checkMethod(), 100))
    }
    this.onResize()
    window.addEventListener('resize', debounce(() => this.onResize(), 100))
  },
  mounted() {
    this.mounted = true

    const path = this.$route.path.split('/')
    if (path.length > 2 && path[1] === 'materialen-en-detaillering') {
      this.linkVisible = false
    }
  },
  methods: {
    onResize() {
      const w = window.innerWidth
      Vue.nextTick(() => {
        this.$store.commit('SET_WIDTH', window.innerWidth)
        // do something cool
      })

      const h = window.innerHeight
      if (window.orientation === 90 || window.orientation === -90) {
        this.$store.commit('SET_WINDOW', 'wide')
        return
      }

      if (w / h >= 16 / 9) {
        this.$store.commit('SET_WINDOW', 'wide')
      } else {
        this.$store.commit('SET_WINDOW', 'tall')
      }
    },
    // scroll checker
    checkMethod() {
      if (window.pageYOffset > pageOffset) {
        document.body.classList.add(scrollClass)
      } else {
        document.body.classList.remove(scrollClass)
      }
    },
    // menu methofs
    setState(state) {
      this.state = state
    },
    clickOutsideMenu() {
      if (this.open) {
        this.open = false
      }
    },
    clickOutsideCard() {
      if (this.showCard) {
        this.showCard = false
      }
    },
    toggle() {
      this.open = !this.open
    },
    // cardje
    openCard(obj) {
      setTimeout(() => {
        this.x = parseInt(obj.offset.left)
        this.y = parseInt(obj.offset.top)
        this.description = obj.description
        this.type = obj.type
        this.url = obj.url
        this.showCard = true
      }, 1)
    },
    cardClick(e) {
      e.preventDefault()
      window.location = this.url
    },
    onDrag(x, y) {
      this.x = x
      this.y = y
    },
    onDragStartCallback(e) {
      if (!e.target.classList.contains('clickable')) {
        e.preventDefault()
      }
    }
  }
})

var VueScrollTo = require('vue-scrollto')
Vue.use(VueScrollTo)
